import { get, getSingle } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { API_Bookmaker } from "../../types";
import { ref, computed, ComputedRef } from "vue";
import { find, meanBy } from "lodash";
export default function fetchBookmakers() {
  const bookmaker = ref<API_Bookmaker[]>([]);
  const loading = ref<boolean>(true);

  const fetchBookmaker = (): Promise<void | API_Bookmaker[]> => {
    return get<API_Bookmaker[]>(GET.BOOKMAKERS, { }).then((result) => {
      loading.value = false;
      bookmaker.value = camelizeKeys(result);
    });
  };
  fetchBookmaker();

  return {
    bookmaker,
    fetchBookmaker,
    loading,
  };
}
