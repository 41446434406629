
import { defineComponent, PropType } from "@vue/runtime-core";
import { Bookmakers } from "../../types";
export default defineComponent({
  props: {
    items: {
      type: Object as PropType<Bookmakers>,
    },
  },
  methods: {
    nameToIcon(name: string): string {
      if (name === "Unlimited withdraw limits") {
        return "viruses";
      } else if (name === "Multiple withdraw methods") {
        return "euro-sign";
      } else if (name === "Live chat open 24/7") {
        return "heart";
      } else if (name === "Mobile-friendly design") {
        return "laptop";
      }
      return "minus";
    },
  },
});
