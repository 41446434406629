
import { defineComponent } from "vue";
import Bookmakers from "../components/Bookmakers/Bookmakers.vue";
import fetchBookmaker from "../misc/composition/fetchBookmakersTable";
import Hero from "@/components/Hero/Hero.vue";
import LoadingPageAnim from "../components/LoadingPageAnim.vue";
export default defineComponent({
  components: { Bookmakers, Hero, LoadingPageAnim },
  setup() {
    const { bookmaker, loading } = fetchBookmaker();
    return { bookmaker, loading };
  },
});
