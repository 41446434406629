import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-92a46548")
const _hoisted_1 = {
  key: 0,
  class: "container bookmaker-table"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_page_anim = _resolveComponent("loading-page-anim")!
  const _component_hero = _resolveComponent("hero")!
  const _component_Bookmakers = _resolveComponent("Bookmakers")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading_page_anim, { loading: _ctx.loading }, null, 8, ["loading"]),
    _createVNode(_component_a_layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_hero, {
              title: "Bookmakers",
              backgroundImage: 
          _ctx.$getFileSource('images/background/bg_certified_casions.png')
        ,
              breadcrumbs: [
          { href: '/', name: 'Coinflip' },
          { href: '#', name: 'Bookmakers' },
        ]
            }, null, 8, ["backgroundImage"]),
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_Bookmakers, { items: _ctx.bookmaker }, null, 8, ["items"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}